@import "colors.scss";

$min-content-width: 1050px;
$collapse-ab-width: 1500px;

$header-height: 30px;

.edit_time_container {
    margin-bottom: 5px;
    border: 1px solid $default-border;
}

/* Modals */

#biModal.modal {
    text-align: center;

    .modal-dialog {
        display: inline-block;
        text-align: left;

        width: auto;
        min-width: 600px;
    }
}

.file-manager .modal-dialog {
    min-width: 900px;
}

.msg-container .modal-dialog {
    min-width: 900px;
}

/* Wochenzeiten */

.week-container {
    margin-top: 10px;
    margin-right: 10px;

    table {
        width: 100%;

        thead th {
            font-size: 16px;
            font-weight: bold;

            padding: 8px;
        }

        tbody {
            td {
                padding: 8px;
            }

            .numeric-value {
                text-align: right;
                white-space: nowrap;
            }
        }

        .time-row {
            border-bottom: 1px solid $default-border;
        }
    }
}

.day-title {
    font-weight: bold;
}
.day-title td {
    border: none!important;
}

/* Misc */

.borm_iconPlace {
    display: inline-block;
    vertical-align: top;
}

.borm_textPlace {
    display: inline-block;
    vertical-align: top;
}

.borm_icon {
    margin-right: 5px;
}

footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;

    padding: 4px 10px 0px 10px;
    height: 30px;
    border-top: 1px solid $default-border;
}

header,
.navbar {
    min-height: $header-height;
    max-height: $header-height;
}

.top-nav {
    position: absolute;
    right: 0;
    top: 0;

    .quick-menu {
        display: inline-block;
        vertical-align: bottom;
        padding-bottom: 1px;

        .btn {
            font-size: 20px;
        }

        .icon-checkin,
        .icon-msg {
            width: 24px;
            height: 24px;
            background-size: 24px 24px;
        }
    }

    .btn {
        border: none!important;
    }

    .top-nav-settings {
        display: inline-block;
        cursor: pointer;

        .top-nav-submenu {
            display: none;
            z-index: 1000;

            position: absolute;
            top: $header-height;
            right: 0;
            margin-top: -1px;

            text-align: left;

            > div {
                padding: 6px;
                white-space: nowrap;
            }

            a {
                display: block;
            }
        }
    }

    .top-nav-settings:hover {
        .top-nav-submenu {
            display: block;
        }
    }
}

.content {
    position: absolute;
    top: 30px;
    bottom: 0px;

    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.sidebar-left {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;

    width: 300px;

    border-right: 1px solid $default-border;

    display: none;
}

.sidebar-left.in {
    display: block;
    z-index: 1000;
}

.view-wrap {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.view-content {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.view {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.breadcrumbs {
    width: 100%;

    .bc-controls {
        width: 100%;
    }
}

.desktop-error {
    position: absolute;
    top: 30px;
    left: 0px;
    right: 0px;

    border-top: 1px solid $default-border;
    padding-left: 4px;
}

.part-title {
    position: absolute;
    display: none;
}

.part-title .btn {
    height: 32px;
    background-color: transparent;
    vertical-align: top;
    border: 1px solid $default-border;
}

.part-title .btn-group {
    border: none;
}

.part-title .btn-group > .btn {
    border: 1px solid $default-border!important;
}

.entries {
    position: absolute;
    top: 40px;
    bottom: 0px;
    left: 0px;

    border-right: 1px solid $default-border;
    width: 450px;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.entries-navigation {
    border-bottom: 1px solid $default-border;
    width: 100%;
    height: 20px;
}

.entry-list {
    position: absolute;
    top: 22px;
    bottom: 0px;

    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .entry {
        border-bottom: 1px solid $default-border;

        cursor: pointer;
        position: relative;

        .entry-buttons {
            position: absolute;
            right: 0;
            top: 0;
            padding-left: 8px;

            background-color: white;
        }

        .entry-check {
            width: 32px;
            vertical-align: middle;
            text-align: center;
            border-right: 1px solid $default-border;
        }

        .entry-block {
            padding: 0 4px;
        }
    }
}

.border {
    border: 1px solid $default-border;
}

.entry-detail {
    position: absolute;
    top: 41px;
    bottom: 0px;
    left: 450px;
    right: 0px;

    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .entry-detail-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .entry-detail-preview {
            flex-grow: 2;
            > * {
                width: 100%;
                height:600px;
                border: 0px;
                border-top: 1px solid $ultra-light;

                button {
                    border: 1px solid $default-border;
                }
                nav {
                    margin: 5px;
                    label {
                        > input {
                            width: 50px;
                            border: 1px solid $default-border;
                        }
                    }
                }
            }
        }
        .entry-detail-info {
            flex-grow: 1;
        }
    }
}

.detail {
    select {
        height: 30px;
    }

    .mc-wrapper {
        min-height: 34px;
    }
}

.detail .content-cell {
    > input {
        height: 28px;
    }

    .bi-wrapper {
        padding-right: 64px;
    }
}

.detail-view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.html-view {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    margin-top: 1px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.print-button {
    display: inline-block;
    position: relative;
}
.select-counter {
    position: absolute;
    z-index: 20;

    width: 33px;
    height: 33px;

    text-align: center;
    padding-top: 6px;

    border-radius: 33px;

    top: 14px;
    right: -14px;
}

.action-buttons-wrapper {
    width: auto;
    padding-right: 10px;

    text-align: right;
    white-space: nowrap;

    position: relative;
}

.action-buttons {
    display: inline-block;
}

.action-buttons-menu {
    display: none;
}

.form-inline {
    margin-bottom: 0px;
}

.defaultCursor {
    cursor: default;
}

.inline-block {
    display: inline-block;
}

/* list group */

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;

    border-radius: 0!important;

    border-left: none;
    border-right: none;
    border-top: 1px solid $default-border;
    border-bottom: 1px solid $default-border;
}

.list-group-item:first-child {
    border-top: none;
}

.list-group-item .pull-right {
    position: absolute;
    top: 0;
    right: 0;

    width: 40px;
    height: 40px;

    padding-top: 10px;
    text-align: center;
}

/* Filters */

.search {
    display: inline-block;
    vertical-align: middle;

    height: 34px; /* + 2 (border) = 36 */
}

.search input {
    height: 34px;
}

.search .btn {
    height: 34px;
    width: 34px;
}

.filter-modal.modal-dialog {
    width: 800px;
}

.filter-column {
    white-space: nowrap;
}
.filter-column > * {
    vertical-align: top;
}

.filter-columns {
    padding-left: 10px;
    border-left: 1px dotted $default-border;
}

.filter-columns-wrapper .filter-label {
    display: inline-block;
    min-width: 40%;
}

.filters {
    display: inline-block;
    margin: 10px 10px;
}

.inline-line > div {
    display: inline-block;
}

.filter-modal .controls .append {
    vertical-align: top;

    .btn {
        height: 33px;
        width: 34px;
        padding: 0;
    }
}

.btn-small {
    height: 20px;
    width: 20px;
    padding: 0;
}

/* Fake Combobox */

.cb-menu-wrapper {
    position: relative;
    display: inline-block;

    text-align: left;

    .cb-menu-arrow {
        width: 22px;
        padding-left: 1px;
        padding-right: 1px;
    }

    .cb-menu-dropdown {
        position: absolute;
        background-color: white;
        border: 1px solid $default-border;
        margin-top: -1px;

        max-height: 400px;
        min-width: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        z-index: 20;
        display: none;

        .cb-menu-entry {
            white-space: nowrap;
            padding: 4px;
            cursor: pointer;
        }
    }
}

.cb-menu-wrapper:hover > .cb-menu-dropdown {
    display: block;
}

/* SIZE RESTRICTION */

.content,
.view-wrap,
.view-content,
.view {
    min-width: $min-content-width;
}

.view {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.sidebar-toggle {
    display: inline-block;

    margin: 4px 2px;
    padding: 3px 9px;
    float: left;

    border: none;
}
.sidebar-toggle:hover,
.sidebar-toggle:focus {
    border: none!important;
}

.navbar-toggle {
    margin: 0px;
    padding: 3px 9px;

    border: none;
}

@media(max-width: $collapse-ab-width) {
    .action-buttons-menu {
        display: inline-block;
    }

    .action-buttons {
        display: none;

        background-color: white;
        border: 1px solid $default-border;
        z-index: 500;

        position: absolute;
        top: 35px;
        right: 10px;

    }

    .action-buttons > * {
        display: block;
    }
    .action-buttons .btn {
        border: none!important;
    }

    .action-buttons-menu:hover~.action-buttons,
    .action-buttons:hover {
        display: block;
    }
}

@media(min-width: 768px) {
    .dropdown-menu.right {
        left: auto;
        right: 0;
    }
}

/* BOOTSTRAP OVERRIDES */

td.alert {
    margin-bottom: 0px;
}

fieldset legend {
    margin-top: 12px;
}

/* Password form */

.passform {
    div {
        margin-top: 5px;
        margin-bottom: 15px;
    }

    input.btn {
        width: 180px;
        border: 1px solid $default-border;
    }
}

/* create button */

.add-entry {
    display: inline-block;
}

/* SubModal */

.sub-modal{
    position: fixed;
    top: 20%;
    left: 50%;

    z-index: 10000;

    border: 1px solid rgba(0, 0, 0, 0.2);

    background-color: white;
}

@media (max-width:767px){
    .sub-modal{
        left: 5%;
    }
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}